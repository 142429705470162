import { Trans } from 'react-i18next';

const texts = {
    "required" : <Trans>Kötelező mező</Trans>,
    "noemail" : <Trans>Nem megfelelő e-mail cím</Trans>
};

const checkRequired = (data) => {
    if (Array.isArray(data.value)) {
        if (data.required && data.value.length === 0) {
            return texts.required;
        }
    }
    else if (data.required && !data.value) {
        return texts.required;
    }
};


const checkValidateEmail = (data) => {
    const validateEmail = (email) => {
        return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };    
    if (data.type === "email" && data.value && !validateEmail(data.value)) {
        return texts.noemail;
    }
};


function Validate(data) {
    let resp;
    resp = checkRequired(data); if (resp) return resp;
    resp = checkValidateEmail(data); if (resp) return resp;
    return '';
}

export default Validate;