import { Table, Pagination, Toggle, List, Checkbox, SelectPicker, Input, Form, InputGroup } from 'rsuite';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import showToast from './../Toast';
import { Link } from "react-router-dom";
import { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faSpinner, faCog, faSearch } from '@fortawesome/free-solid-svg-icons'
import Formgenerator from "../Form";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { throwError } from "../Error";
import Rightside from "../Rightside";
import Storage from '../../routes/storage';
import { Row, Col, Button } from 'react-bootstrap';
import { IsMobile } from '../Device';
import Permission from '../Permission';
import { useTranslation } from 'react-i18next';

function Listgenerator ({title, columns, buttons, action, deleteurl, edit, paginationServer, module, ...props}) {
    const { t } = useTranslation();
    let [data, setData] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [pendingDelete, setPendingDelete] = useState(-1);
    const [showOffcanvas, setOffcanvas] = useState([false,0]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(paginationServer ? (Storage.get("pageData",action.replace(":id", "")) ? Storage.get("pageData",action.replace(":id", "")).page : 1) : -1);
    const [perPage, setPerPage] = useState(Storage.get("pageData",action.replace(":id", "")) ? Storage.get("pageData",action.replace(":id", "")).perPage : (props.paginationPerPage ? props.paginationPerPage : 10));
    const [sortColumn, setSortColumn] = useState(Storage.get("sortData",action.replace(":id", "")) ? Storage.get("sortData",action.replace(":id", "")).sortColumn : (columns.length > 0 ? columns[0].id : ""));
    const [sortType, setSortType] = useState(Storage.get("sortData",action.replace(":id", "")) ? Storage.get("sortData",action.replace(":id", "")).sortType : "asc");
    const [searchValue, setSearchValue] = useState("");
    const filterValues = useMemo(() => ({
        filter: {},
        lastId: ""
    }), []);

    // Default pagedata mentése
    Storage.add("pageData", {[action.replace(":id", "")]: {page: page, perPage: perPage}});

    // Egy oldalon megjelenítés módosítása
    const handleChangeLimit = dataKey => {
        setIsLoaded(false);
        setPage(1);
        setPerPage(dataKey);
    };

    // Oszlop sorrendek módosítása
    const handleSortColumn = (newSortColumn, newSortType) => {
        setIsLoaded(false);
        setSortColumn(newSortColumn);
        setSortType(newSortType);
        Storage.add("sortData", {[action.replace(":id", "")]: {sortColumn: newSortColumn, sortType: newSortType}});
    };

    // Oszlop méretek módosítása
    const handleResize = (columnWidth, dataKey) => {
        console.log(columnWidth);
        console.log(dataKey);
        // Storage.add("resizeData", {[action.replace(":id", "")]: {columnWidth: columnWidth, sortType: newSortType}});
    };

    // Adatok lekérése
    const fetchData = () => {
        if (!action) return showToast.error(t("Nincs megadva az action attribútum, a lista nem kérhető le!"));
        let url = action.replace(":id", "");
        if (url.includes("?")) url += "&";
        else url += "?";
        url += `page=${page}&perpage=${perPage}&orderby=${sortColumn}&orderway=${sortType}&search=${searchValue}&filter=${JSON.stringify(filterValues.filter ? filterValues.filter : {})}`;
        return axios.get(url)
        .then(function (response) {
            setIsLoaded(true);         
            if (response.data.success) {
                setTotalRows(response.data.pager.count_rows);
                setData(response.data.response.data);            
            }
            throwError(response);            
            return response;
        }).catch(function (error) {
            showToast.error(error);
        });
    }

    const query = useQuery({
        queryKey: [`${module}List`, page, perPage, sortColumn, sortType, searchValue, filterValues],
        queryFn: () => fetchData(),
        refetchOnWindowFocus: true
    });    


    // Törlés modal
    function deleteRow(action,id,event) {
        if (!action) return showToast.error(t("Nincs megadva az action attribútum, az url nem kérhető le!"));   
        
        function doDelete(id) {
            setPendingDelete(id);
            axios.delete(deleteurl.replace(":id", id))
                .then(function (response) {
                    throwError(response);
                    fetchData();
                    setPendingDelete(-1);
                })
                .catch(function (error) {
                    showToast.error(error);
                });  
        }

        const MySwal = withReactContent(Swal)
        MySwal.fire({
            icon: "error",
            text: t("Biztosan töröljük?"),
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Igen',
            cancelButtonText: 'Mégsem',
            confirmButtonColor: '#d33',
            showLoaderOnConfirm: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    doDelete(id);
                }
            })

    }

    columns = Object.keys(columns).map((id) => {
        const column = columns[id];
        id = column.id;
        if (column.sortable === undefined) column.sortable = true;
        if (column.hideable === undefined) column.hideable = true;
        if (id === "_functions_") {
            if (!column.label) column.label = t("Műveletek");
            column.hideable = false;

            // Check permission
            if (!<Permission permissions={["PUT_"+module,"DELETE_"+module]} />) return false;
        }
        return column;
    });
    columns = columns.filter(column => column !== false);

    // Check columndata ugyanaz, mint most és akkor lekérjük storageból, ha nem, akkor törüljök ott
    let columnData = Storage.get("columnData",action.replace(":id", ""));
    if (columnData) {
        columns = columnData.map(column => {
            let newColumn = columns.find(newColumn => newColumn.id === column.id);
            if (newColumn) {
                newColumn.hide = column.hide;
            }
            return newColumn;
        });
    }

    if (data && Object.keys(data).length > 0) {
        data = Object.keys(data).map((id) => {
            return {id, ...data[id]};
        });
    }
    if (!buttons) buttons = {};

    
    // Jobb oldali szerkesztő
    function ListOffCanvas({title, action, fields, ...props}) {
        const onSubmit = (event) => {
            event.preventDefault();
            event.stopPropagation();
            let data = {};
            for (let i = 0; i < event.target.elements.length; i++) {
                if (event.target.elements[i].type === "submit") continue;
                data[event.target.elements[i].id] = event.target.elements[i].value;
            }
            axios.request({
                method: showOffcanvas[1] ? "put" : "post",
                url: action.replace(":id", showOffcanvas[1]),
                data: data
            }).then(function (response) {
                throwError(response);
                setOffcanvas([false,0]);
                fetchData();
            })
            .catch(function (error) {
                showToast.error(error);
            });
        }
        return <Rightside show={showOffcanvas[0]} close={() => setOffcanvas([false,0])} title={title ? title : t(`Szerkesztés`)} body={<Formgenerator cancelurl={() => setOffcanvas([false,0])} onSubmit={onSubmit} action={action.replace(":id", showOffcanvas[1])} fields={fields} id={showOffcanvas[1]} />} />
    }

    function TableSettings({columns, onChange}) {
        const [show, setShow] = useState(false);

        // Oszlop elrejtés
        const handleHideColumn = (checked, event) => {
            let newColumns = columns.map(column => {
                if (column.id === event.target.id) {
                    column.hide = !checked ? true : false;
                }
                return column;
            });
            onChange(newColumns);
            Storage.add("columnData", {[action.replace(":id", "")]: newColumns.map(column => {
                return {id: column.id, hide: column.hide};
            })});
        }

        // Oszlop mozgatás
        const handleSortColumn = (event) => {
            const { oldIndex, newIndex } = event;
            const newColumns = [...columns];
            const item = newColumns.splice(oldIndex, 1)[0];
            newColumns.splice(newIndex, 0, item);
            onChange(newColumns);
            Storage.add("columnData", {[action.replace(":id", "")]: newColumns.map(column => {
                return {id: column.id, hide: column.hide};
            })});

        }

        let columnData = Storage.get("columnData",action.replace(":id", "")) || [];
        return <div className="d-inline-block position-relative">
            <Button variant="light" type="button" onClick={() => setShow(!show)}><FontAwesomeIcon icon={faCog} /></Button>
            <div className="position-absolute p-2 text-start rounded" style={{zIndex: 99, width: "200px", left: "-160px", display: show ? "block" : "none"}}>
                <List size="sm" sortable bordered pressDelay={100} onSort={handleSortColumn}>
                    {columns.filter(column => column.hideable !== false).map((column, i) => {
                        return <List.Item key={i} index={i}><Toggle size="sm" id={column.id} onChange={handleHideColumn} defaultChecked={columnData.find(newColumn => newColumn.id === column.id) ? !columnData.find(newColumn => newColumn.id === column.id).hide : true} disabled={column.hideable !== true ? true : false} /> {column.label}</List.Item>
                    })}
                </List>
            </div>
        </div>
    }

    function RenderTable({columns, data, selectableRows, onSelectedRowsChange, globalSearch, columnSearch, ...props}) {
        const [_columns, setColumns] = useState(columns);
        const [checkedKeys, setCheckedKeys] = useState([]);

        let checked = false;
        let indeterminate = false;

        const handleCheckAll = (value, checked) => {
            const keys = checked ? data.map(item => item.id) : [];
            onSelectedRowsChange(keys);
            setCheckedKeys(keys);
        };
        const handleCheck = (value, checked) => {
            const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
            onSelectedRowsChange(keys);
            setCheckedKeys(keys);            
        };      
        if (data && checkedKeys.length === data.length) checked = true;
        else if (checkedKeys.length === 0) checked = false;
        else if (checkedKeys.length > 0 && checkedKeys.length < data.length) indeterminate = true;


        return <>
            <Row className="align-items-center w-100">
                <Col sm={{span: 4}} lg={{span: 8}}><h2>{title}</h2></Col>
                <Col sm={{span: 8}} lg={{span: 4}} className="text-end">
                    <Row>
                        <Col xs={{span: 11}} className="text-end">
                            {globalSearch && 
                            <Form onSubmit={(value, event) => {event.preventDefault(); setIsLoaded(false); setSearchValue(event.target.elements[0].value); fetchData();}}>
                                <InputGroup>
                                    <Form.Control name="search" type="text" placeholder="Keresés..." onKeyDown={(e) => { if (e.key === "Escape") { setIsLoaded(false); setSearchValue(""); fetchData(); }}} defaultValue={searchValue} />
                                    <InputGroup.Button>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </InputGroup.Button>
                                </InputGroup>                                
                            </Form>
                            }
                        </Col>
                        <Col xs={{span: 1}}><TableSettings columns={_columns} onChange={setColumns} /></Col>
                    </Row>
                </Col>
            </Row>
            <div className="w-100 position-relative" style={{height: paginationServer ? "calc(100vh - 260px)" : "calc(100vh - 190px)"}}>
            <Table
                fillHeight={true}
                loading={!isLoaded}
                data={data && Object.values(data)}
                locale={{loading: "Pillanat...", emptyMessage: "Nincs adat"}}
                rowHeight={48}
                rowClassName="rs-table-row-custom"
                onSortColumn={handleSortColumn}
                sortColumn={sortColumn}
                sortType={sortType}
                onResize={handleResize}
                headerHeight={columnSearch ? 100 : 40}
                {...props}
            >
                {(selectableRows && !IsMobile()) && <Table.Column flexGrow={0} width={50} verticalAlign="middle" align="center">
                    <Table.HeaderCell style={{ padding: 0 }}>
                        <Checkbox
                            inline
                            checked={checked}
                            indeterminate={indeterminate}
                            onChange={handleCheckAll}
                        />
                    </Table.HeaderCell>
                    <Table.Cell style={{ padding: 0 }}>
                        {rowData => 
                            <div style={{ lineHeight: '46px' }}>
                            <Checkbox
                                value={rowData["id"]}
                                inline
                                onChange={handleCheck}
                                checked={checkedKeys.some(item => item === rowData["id"])}
                            />
                            </div>
                        }
                    </Table.Cell>                    
                </Table.Column>}
                {_columns.filter(column => (column.hide !== true && column.id !== '_functions_')).map((column, i) => {
                    let { id, label, cell, hideable, hide, searchable, searchtype, searchlike, searchid, data, ...rest } = column;
                    if (data) {
                        cell = (rowData) => {
                            let item = data.find(item => item.value == rowData[id]);
                            return item ? item.label : "";
                        }
                    }


                    let timeoutId = '';
                    function handleFilterChange(value, e) {
                        let delay = (e === undefined || e.type === "click" || value === null) ? 0 : 500;
                        if (timeoutId !== '') clearTimeout(timeoutId);
                        timeoutId = setTimeout(() => {
                            setIsLoaded(false);               
                            if (value === "" || value === null) {
                                if (filterValues.filter[id]) delete filterValues.filter[id];
                            }
                            else {
                                filterValues.filter[id] = {
                                    type: searchtype ? searchtype : "text",
                                    like: searchlike ? searchlike : "end",
                                    id: searchid ? searchid : id,
                                    value: value
                                };
                                filterValues.lastId = id;
                            }
                            setPage(1);
                            fetchData();
                        }, delay);
                    }
            
                    return (
                        (columnSearch && searchable && !IsMobile()) ? 
                            <Table.ColumnGroup key={id} header={column.searchtype === "select" ?
                                    <SelectPicker 
                                        size="sm" 
                                        data={column.data} 
                                        placeholder={`${label} ${t("keresés...")}`} 
                                        onChange={handleFilterChange} 
                                        block={true}
                                        defaultValue={filterValues.filter[id] ? filterValues.filter[id].value : ""}
                                    />
                                    :
                                    <Input
                                        size="sm" 
                                        type="text" 
                                        defaultValue={filterValues.filter[id] ? filterValues.filter[id].value : ""}
                                        placeholder={`${label} ${t("keresés...")}`} 
                                        onKeyDown={(e) => { if (e.key === "Escape") { handleFilterChange(null); }}} 
                                        onChange={handleFilterChange}
                                        autoFocus={filterValues.lastId === id}
                                     />
                                }>
                                <Table.Column key={id} flexGrow={1} minWidth={200} verticalAlign="middle" {...rest}>
                                    <Table.HeaderCell>{label}</Table.HeaderCell>
                                    <Table.Cell dataKey={id}>{cell && (rowData => cell(rowData))}</Table.Cell>
                                </Table.Column>
                            </Table.ColumnGroup>
                            :
                            <Table.Column key={id} flexGrow={1} minWidth={200} verticalAlign="middle" {...rest}>
                                <Table.HeaderCell>{label}</Table.HeaderCell>
                                <Table.Cell dataKey={id}>{cell && (rowData => cell(rowData))}</Table.Cell>
                            </Table.Column>  
                    );
                })}
                {_columns.some(column => column.id === "_functions_") && 
                    <Table.Column flexGrow={0} width={90} verticalAlign="middle" align="right" fixed="right">
                        <Table.HeaderCell>{_columns.find(column => column.id === "_functions_").label}</Table.HeaderCell>
                        <Table.Cell>
                            {rowData => 
                                <>
                                    {<Permission permission={"PUT_"+module} /> &&
                                        (edit ? <button type="button" className="btn btn-sm btn-light me-1" onClick={(event) => setOffcanvas([true,rowData.id])}><FontAwesomeIcon icon={faEdit} /></button> : <Link className="btn btn-sm btn-light me-1" to={pendingDelete === rowData.id ? "#" : `${window.location.href}/edit/${rowData.id}`}><FontAwesomeIcon icon={faEdit} /></Link>)
                                    }
                                    {<Permission permission={"DELETE_"+module} /> &&
                                        (<button type="button" className="btn btn-sm btn-danger" onClick={(event) => deleteRow(action,rowData.id,event)} disabled={pendingDelete === rowData.id ? true : false}><FontAwesomeIcon icon={pendingDelete === rowData.id ? faSpinner : faTrash} spin={pendingDelete === rowData.id ? true : false} /></button>)
                                    }
                                </>
                            }
                        </Table.Cell>
                    </Table.Column>
                }
            </Table>
        </div>
        {paginationServer && <div className={IsMobile() ? "mx-auto mt-2" : "ms-auto"}>
            <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks={IsMobile() ? false : true}
                maxButtons={IsMobile() ? 3 : 5}
                size="sm"
                layout={IsMobile() ? ['pager'] : ['total', '|', 'limit', 'pager']}
                total={totalRows}
                limitOptions={props.paginationLimits ? props.paginationLimits : [10, 30, 50, 100]}
                limit={perPage}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
                locale={{total: 'Találatok: {0}', limit: '{0} / oldal', page: 'Oldal'}}
            />
            </div>}        
        </>
    }

    return (
        <>
            {Object.values(buttons).map((field, id) => {
                if (!field.id) field.id = id;
                if (!field.label) field.label = t("Új létrehozása");
                if (!field.url) field.url = `${window.location.href}/edit`;
                return (edit ? <button type="button" key={field.id + `topButton`} className="btn btn-sm btn-primary mb-3 d-block" onClick={(event) => setOffcanvas([true,0])}>{field.label}</button> : <Link key={field.id + `topButton`} className="btn btn-sm btn-primary mb-3" to={field.url}>{field.label}</Link>);
            })}        
            <RenderTable columns={columns} data={data} {...props} />        
            {edit && <ListOffCanvas key={JSON.stringify(edit)} title={edit.title} action={edit.action} fields={edit.fields} />}         
        </>
	);
}



export default Listgenerator;