import { Outlet } from 'react-router-dom';
import Help from './help';
import Notification from './notification.jsx';
import User from './user';
import MainSidebar from './mainsidebar.jsx';

export const Header = () => 
    <header className="position-fixed z-5" style={{top: 0, left: 0, width: "100%", height: "var(--header-top-height)"}}>
        <div className='container-fluid px-4 py-0 border-bottom h-100'>
            <div className='navbar navbar-expand-lg d-flex flex-nowrap justify-content-end py-0 h-100'>
                <Help />                
                <Notification />
                <User />
            </div>
        </div >    
    </header>

export const Footer = () => <></>

export function MainContent() {
    return (
        <div className="admin">
            <div className='d-flex align-items-start align-self-stretch h-100 overflow-auto'>
                <MainSidebar />
                <main className="main-content d-flex flex-column justify-content-start align-items-start w-100 p-3" style={{marginTop: "var(--header-top-height)"}}>
                    <Header />
                    <Outlet />
                </main>        
            </div>
            <Footer />
        </div>
    );
}