import { Avatar as RAvatar, Loader } from 'rsuite';

export function GetInitials({name}) {
    return name.charAt(0).toUpperCase() + (name.split(" ")[1] ? name.split(" ")[1].charAt(0).toUpperCase() : name.charAt(1).toUpperCase());
}

export default function Avatar({loader, ...props}) {
    return (
        <RAvatar {...props} circle src={props.image && props.image} size={props.size ? props.size : "sm"} style={{ background: '#000' }}>{loader ? <Loader backdrop center /> : (!props.image && <GetInitials name={props.name} />)}</RAvatar>
    )
}