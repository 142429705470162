// import { createContext } from 'react';
// import { useEffect, useState } from 'react';


export const IsMobile = () => {
    const breakpoint = 768;
    const checkForDevice = () => window.innerWidth < breakpoint;
    // const [isMobile, setIsMobile] = useState(checkForDevice());
    
    return checkForDevice();
/*
    useEffect(() => {
        if (onChange) onChange(isMobile);
    }, [isMobile, onChange]);    

    useEffect(() => {
        const handlePageResized = () => {
            setIsMobile(checkForDevice());
        }
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handlePageResized);
            window.addEventListener('orientationchange', handlePageResized);
            window.addEventListener('load', handlePageResized);
            window.addEventListener('reload', handlePageResized);
        }
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handlePageResized);
                window.removeEventListener('orientationchange', handlePageResized);
                window.removeEventListener('load', handlePageResized);
                window.removeEventListener('reload', handlePageResized);
            }
        }
    },[]);

    return isMobile;
*/
}


// export default IsMobile;