import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Editor = ({value, onChange, ...props}) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            data={value && value}
            onChange={(event, editor) => {
                if (editor) {
                    const content = editor.getData();
                    onChange(content);
                }
            }}
            config={{
                toolbar: [ 'undo', 'redo', '|', 'bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList', '|', 'blockQuote']
            }}            
        />
    );
}

export default Editor;