import axios from "axios";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { throwError } from "../components/Error";
import showToast from "../components/Toast";
import { Navigate } from "react-router-dom";

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [langs, setLangs] = useState({});

    function getMe() {
        axios.get(`${process.env.REACT_APP_API_END_POINT}/user`)
            .then((response) => {
                throwError(response);
                if (!response.data.response.data.permissions) response.data.response.data.permissions = [];
                setUser(response.data.response.data);                
                setLangs(response.data.response.langs);                
            }).catch(function (error) {
                showToast.error(error);
            });
    }
    useEffect(() => {
        getMe();
    }, [])    

    const contextValue = useMemo(
        () => ({
            user,
            setUser,
            getMe,
            langs
        }),
        [user, langs]
    );

    return (
        <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
    );
};

export const useUserData = () => {
    return useContext(UserContext);
};

export default UserProvider;