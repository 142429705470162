import { useTranslation } from "react-i18next";
import { Form, Button, Card, Tab, Tabs, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Listgenerator from "../List";
import Select from 'react-select';
import { useState, useEffect, useRef, forwardRef } from 'react';
import axios from 'axios';
import Validate from './validate';
import showToast from './../Toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { DatePicker, Checkbox, Radio, Divider } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { throwError } from "../Error";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faCog } from '@fortawesome/free-solid-svg-icons'
import Rightside from "../Rightside";

// Fields
import Editor from "./editor";
import File from "./file";


// Statik nézet
const viewModeContent = ((children) => <div className="d-flex formGroupStatic text-md-semibold">{children}</div>);

// Template rajzolása
function Template ({input, prepend, append, info, errortext, ...field}) {
    return (
    <Form.Group className="formGroup mb-3">
        {field.label && <Form.Label>
            {field.label}
            {field.required && <span className="text-danger">&nbsp;*</span>}
        </Form.Label>}
        {prepend || append ? 
            <InputGroup>
                {prepend && typeof prepend === "string" ? <InputGroup.Text>{prepend}</InputGroup.Text> : prepend}
                {input}
                {append && typeof append === "string" ? <InputGroup.Text>{append}</InputGroup.Text> : append}
            </InputGroup>
        : input}                
        {info && <Form.Text className="text-muted">{info}</Form.Text>}
        {errortext && <Form.Text className="text-danger">{errortext}</Form.Text>}
    </Form.Group>
    );
}

// Alapértékek beállítása 
function PreSet(fields) {
    const { t } = useTranslation();
    let tempFields = fields ? {...fields} : {};
    tempFields = Object.values(tempFields).map((field, id) => {
        if (field.children) return {...field, children: PreSet(field.children)};
        else if (field.group) return {...field, group: PreSet(field.group)};        

        if (!field.id) field.id = field.type + "_" +id;
        if (!field.name) field.name = field.id;        
        if (!field.errortext) field = {...field, errortext: ''};
        if (!field.info) field = {...field, info: ''};
        if (field.type === "file") { // plusz mező a fileoknak
            field.children = [{id: field.id, multiple: (field.multiple ? true : false), type: "hidden", value: [], filekey: [], errortext: ""}]
            field.id = field.id + "_file";
        }
        else if (field.type === "seo") {
            field.children = [
                {
                    id: "seo[url]",
                    type: "text",
                    label: t("URL"),
                },
                {
                    id: "seo[title]",
                    type: "text",
                    label: t("Meta cím"),
                },
                {
                    id: "seo[description]",
                    type: "textarea",
                    label: t("Meta leírás"),
                },
                {
                    id: "seo[keywords]",
                    type: "text",
                    label: t("Meta kulcsszavak"),
                }
            ]
        }
        if ((field.type === "checkbox" || field.type === "radio") && !field.checked) field.checked = false;
        return field;
    });
    return tempFields;
}

// Adatok betöltése
function setDefaultValues (searchArea, data) {
    return Object.values(searchArea).map((field) => {
        let id = field.id;
        if (field.type === "file") id = id.slice(0, -5);
        else if (field.type === "seo") id = "seo";
        if (data.hasOwnProperty(id)) {
            if (field.type === "typeahead") {
                // return {...field, defaultSelected: data[field.id] ? data[field.id] : ""};
                return {...field, value: data[id] ? data[id] : ""};
            }
            else if (field.type === "select") {
                if (field.multiple) {
                    let value = [];
                    data[id].map((item) => {
                        value.push({value: item, label: field.options && (field.options.find((option) => option.value == item).label)});
                        return item;
                    });
                    return {...field, value: value};
                }
                else {
                    if (field.options) return {...field, value: {value: data[id] ? data[id] : "", label: field.options.find((item) => item.value == data[id]) ? field.options.find((item) => item.value == data[id]).label : ""}};
                    else return {...field, value: {value: data[id] ? data[id] : "", label: ""}};
                }
            }
            else if (field.type === "date" || field.type === "datetime") {
                return {...field, value: data[id] ? new Date(data[id]) : ""};
            }
            else if (field.type === "time") {
                return {...field, value: data[id] ? new Date(new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate() + ' ' + data[id] + ':00') : ""};
            }
            else if (field.type === "file") {
                if (field.multiple) {
                    field.children[0].value = data[id] ? data[id].map((item) => item.id) : [];
                    field.children[0].filekey = data[id] ? data[id].map((item) => item.fileKey) : [];
                }
                else {
                    field.children[0].value = data[id].id ? [data[id].id] : [];
                    field.children[0].filekey = data[id].fileKey ? [data[id].fileKey] : [];
                }
                return {...field, value: data[id] ? (field.multiple ? data[id] : (data[id].file ? [data[id]] : [])) : [], children: field.children};
            }
            else if (field.type === "seo") {
                let children = {...field.children};
                children = Object.values(children).map((child) => {
                    return {...child, id: child.id.split("[")[1].slice(0, -1), name: child.id}
                });
                return {...field, children: setDefaultValues(children, data[id] ? data[id] : "")};
            }
            else {
                return {...field, value: data[id] ? data[id] : ""};
            }
        }
        else if (field.children) return {...field, children: setDefaultValues(field.children, data)};
        else if (field.group) return {...field, group: setDefaultValues(field.group, data)};
        else return {...field};
    });
}         

// Adatok beállítása a tömbben
function setValue (searchArea, id, value, set) {
    return Object.values(searchArea).map((field) => {
        if (field.id === id) {
            // field = {...field, value: value};
            field[set] = value;
            return {...field, errortext: Validate(field)};
        }
        else if (field.children) return {...field, children: setValue(field.children, id, value, set)};
        else if (field.group) return {...field, group: setValue(field.group, id, value, set)};
        else return {...field, errortext: Validate(field)};
    });
}

function Formgenerator ({id = 0, fields, onSubmit, cancelurl, options, module, ref, ...props}) {
    const { t } = useTranslation();
    const [formData, setFormData] = useState(fields ? PreSet(fields) : []);
    const [isLoaded, setIsLoaded] = useState(id ? false : true);
    const [submitDisabled] = useState(false);
    const SubmitButtonRef = useRef(null);
    const SubmitButtonLoaderRef = useRef(null);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const lang = searchParams.get("lang");

    useEffect(() => {
        if (id) {
            if (!props.action) return showToast.error(t("Nincs megadva az action attribútum, a form nem kérhető le!"));       
            axios.get(props.action.replace(":id", id))
            .then(function (response) {
                throwError(response);
                if (response.data.response.data) {
                    let newFormData = formData ? {...formData} : {};
                    newFormData = setDefaultValues(newFormData, response.data.response.data);
                    newFormData = setValue(newFormData);
                    // console.log(newFormData);
                    setFormData(newFormData);
                    setIsLoaded(true);
                }
                else {
                    showToast.error(t("Nem található a kért adat!"));
                }
            })
            .catch(function (error) {
                showToast.error(error);
            });   
        }
    },[]);

    // Check disabled submit button
    function checkSubmitDisabled (searchArea, force = false) {
        let valid = []; let check;
        function checkSubmitDisabledDo (searchArea) {
            return Object.values(searchArea).map((field) => {
                if (field.children) checkSubmitDisabledDo(field.children);
                else if (field.group) checkSubmitDisabledDo(field.group);
                else if (Validate(field)) valid.push(true);
                else valid.push(false);
                return field;                
            });
        }
        if (!force) {
            checkSubmitDisabledDo(searchArea);
            check = valid.some((item) => item === true);
        }
        else {
            check = force;
        }
        if (SubmitButtonRef.current) SubmitButtonRef.current.disabled = check;
        return check;
    }


    // Mentés gombra mentés
    if (!onSubmit) {
        onSubmit = (event) => {
            event.preventDefault();      
            // event.stopPropagation();
            if (SubmitButtonRef.current) {
                SubmitButtonRef.current.disabled = true;
                SubmitButtonRef.current.children[1].classList.add("d-none");
                SubmitButtonLoaderRef.current.classList.remove("d-none");
            }
            let sendData = {};
            let error = false; let submitId = [];

            function getValues (searchArea) {                
                Object.values(searchArea).map((field) => {
                    if (!field.name) field.name = field.id;
                    if (field.children) getValues(field.children);
                    else if (field.group) getValues(field.group);
                    else if (field.type === "submit") submitId.push(id);
                    else if (field.type === "content") {}
                    else if (field.type === "custom") {}
                    else if (field.type === "divider") {}
                    else if (field.type === "file") {}
                    else {
                        let value = field.value ? field.value : (field.defaultValue ? field.defaultValue : "");
                        if (field.type === "select") value = field.multiple ? value.map((item) => item.value) : value.value;
                        if (field.name.split("[").length > 1) {
                            let temp = sendData[field.name.split("[")[0]] ? {...sendData[field.name.split("[")[0]], [field.name.split("[")[1].slice(0, -1)]: value} : {[field.name.split("[")[1].slice(0, -1)]: value};
                            sendData[field.name.split("[")[0]] = temp;
                        }
                        else {
                            sendData[field.name.split("[")[0]] = value;
                        }
                    }
                    return field;
                });
            }         
            getValues(formData);   
            if (error) {
                if (SubmitButtonRef.current) {
                    SubmitButtonRef.current.disabled = false;
                    SubmitButtonRef.current.children[1].classList.remove("d-none");
                    SubmitButtonLoaderRef.current.classList.add("d-none");                    
                }                
                return false;
            }

            // Nyelv hozzáadása
            sendData["_lang_"] = (lang ? lang : process.env.REACT_APP_DEFAULT_LANG);

            if (options && options.beforeSubmit) {
                let result = options.beforeSubmit(sendData, event);
                if (result) sendData = result;
            }

            console.log("URL", id ? "put" : "post", props.action.replace(":id", id));
            console.log("datas", sendData);
            // return false;

            if (!props.action) return showToast.error(t("Nincs megadva az action attribútum, a form nem küldhető el!"));       
            axios.request({
                method: id ? "put" : "post",
                url: props.action.replace(":id", id),
                data: sendData
            }).then(function (response) {
                if (response.data.success) {
                    showToast.success(id ? t("Sikeres módosítás!") : t("Sikeres mentés!"));
                    if (options && options.afterSubmit) options.afterSubmit(response.data.response, event);
                    if (cancelurl) navigate(cancelurl);
                }
                if (SubmitButtonRef.current) {
                    SubmitButtonRef.current.disabled = false;
                    SubmitButtonRef.current.children[1].classList.remove("d-none");
                    SubmitButtonLoaderRef.current.classList.add("d-none");                    
                }                
                throwError(response);                
            })
            .catch(function (error) {
                showToast.error(error);
            });            
        };
    }

    // Render fields
    // const RenderField = ({...fieldData}) => {
    const RenderField = forwardRef(({...fieldData}, ref) => {
        let [field, setField] = useState(fieldData);
        let [showRightside, setShowRightside] = useState(false);

        // Submit button disabled beállítása
        useEffect(() => {
            if (!submitDisabled) checkSubmitDisabled(setValue(formData, field.id, field.value, "value"));
        },[field]);

        // Input változások figyelése
        function inputChangeHandler(event, src) {
            let newField = {...field};
            if (field.type === "checkbox" || field.type === "radio") newField = {...newField, checked: src};
            else if (field.type === "editor") newField = {...newField, value: event};
            else newField = {...newField, value: src !== undefined ? event : event.target.value};
            let error = Validate(newField);
            newField = {...newField, errortext: error}
            setField(newField);
        }

        // Typeahead kereséshez
        const handleSearch = (query) => {
            axios.post(`${field.remote}?page=-1`, {query: query})
              .then((items) => {
                throwError(items);
                setField({...field, options: items.data.response.data});
              });
        };
        // Távoli select betöltéshez
        const handleLoad = async (url) => {
            axios.get(`${url}?page=-1`)
              .then((items) => {
                throwError(items);
                let newField = {...field, options: items.data.response.data};
                if (newField.value && newField.options) {
                    if (newField.multiple) {
                        let value = [];
                        newField.value.map((item) => {
                            value.push({value: item.id, label: newField.options.find((option) => option.id == item.id).name});
                            return item;
                        });
                        newField = {...newField, value: value};
                    }
                    else {
                        newField = {...newField, value: {value: newField.value.value ? newField.value.value : "", label: newField.options.find((item) => item.id == newField.value.value) ? newField.options.find((item) => item.id == newField.value.value).name : ""}};
                    }
                }
                newField = {...newField, options: newField.options ? newField.options.map((item) => {return {value: item.id, label: item.name}}) : [], load: null};
                setField(newField);    
              });
        };
        useEffect(() => {
            if (field.load) handleLoad(field.load);
        }, [field.load]);

        // Skeleton
        const RenderTemplate = (input, props) => {
            return <Template input={!isLoaded ? <Skeleton height="36px" /> : input} {...props} />;
        }
        
        // Multi language --> TODO, backenden kezelni
        /* if (field.multilanguage) {
            if (langs.length > 1) {
                return <Card key={field.id + "cardMultiLanguage"} className="mb-3">
                    <Card.Header>{field.label}</Card.Header>
                    <Card.Body>{Object.keys(langs).map((lang,id) => {
                        let temp = {...field};
                        temp.id = lang + "[" + temp.id + "]";
                        temp.prepend = "" + langs[lang] + "";
                        temp.label = "";
                        delete temp.multilanguage;
                        // return RenderField(temp);
                        return <RenderField key={field.id + "render-ml" + id} {...temp} />;
                    })}</Card.Body>
                </Card>
            }
            else {
                setField({...field, id: Object.keys(langs)[0] + "[" + field.id + "]", multilanguage: false});
                return false;
            }
        } */

        // Group
        if (field.group) {
            return <Row>
                <Col key={field.id + "col"}>{RenderTemplate(<Form.Control onChange={inputChangeHandler} type={field.type} {...field} />, field)}</Col>
                {field.group.map((item, id) => {
                    return <Col key={field.id + "col" + id}>{RenderField(item)}</Col>;
                })}
                </Row>;
        }

        // Type switch
        switch (field.type) {
            case "tab":
                return (
                    <Tabs key={field.id + "tabs"} defaultActiveKey={field.id + "link-0"}  className="mb-3">
                        {field.children.map((child, id) => {
                            if (!child.id) child.id = id;
                            return <Tab eventKey={field.id + "link-" + id} key={field.id + "tab" + child.id} title={child.label}>
                                 {child.children.map((item, id) => {
                                    // return RenderField(item);
                                    return <RenderField key={field.id + "render-tab" + id} {...item} />;
                                })}
                            </Tab>
                        })}                        
                    </Tabs>
                );
            case "card":
                return (
                    <Card key={field.id + "card"} className="mb-3">
                        <Card.Header>{field.label}</Card.Header>
                        <Card.Body>
                            {field.children.map((child, id) => {
                                // return RenderField(child);
                                return <RenderField key={field.id + "render-card" + id} {...child} />;
                            })}
                        </Card.Body>
                    </Card>
                );
            case "static":
                 return RenderField(viewModeContent(field.content), field);
            case "custom":
                return RenderTemplate(field.content, field);
            case "content":
                return field.content ? field.content : "";
            case "submit":
                if (submitDisabled) field = {...field, disabled: true};
                return <div key={field.id + "buttons"} className="d-flex align-items-center">
                    {!isLoaded ? <Skeleton duration={0.8} width="100px" height="38px" /> : <Button ref={SubmitButtonRef} type="submit" variant="primary" {...field}><FontAwesomeIcon icon={faSpinner} spin={true} className="d-none" ref={SubmitButtonLoaderRef} /><span>{field.label}</span></Button>}
                    {cancelurl && <Button variant="secondary" className={!isLoaded ? "mt-1 ms-1" : "ms-1"} onClick={() => (typeof cancelurl === "string" ? navigate(cancelurl) : cancelurl)}>{t("Mégsem")}</Button>}
                </div>;
            case "textarea":         
                return RenderTemplate(<Form.Control onChange={inputChangeHandler} as={field.type} {...field}  />, field);
            case "select":
                if (field.editable) {
                    let editable = {
                        action: field.load.replace(":id", id),
                        columns: [
                            {
                                id: "label",
                                label: t("Megnevezés"),
                                sortable: true
                            },
                            {
                                id: "_functions_"
                            }                            
                        ],
                        buttons: {
                            add: {
                                label: t("Új létrehozása")
                            }
                        },
                        edit: {
                            action: field.load+"?id=:id",
                            fields: [
                                {
                                    id: "name",
                                    type: "text",
                                    label: t("Megnevezés"),
                                    required: true
                                }
                            ]
                        }
                    };
                    field.append = <><Button variant="outline-primary" type="button" onClick={() => setShowRightside(true)}><FontAwesomeIcon icon={faCog} /></Button><Rightside show={showRightside} close={() => setShowRightside(false)} title={t("Szerkesztés")} body={<Listgenerator key={field.id + "editable"} {...editable} />} /></>;
                }
                return RenderTemplate(
                    <Col><Select isLoading={field.load ? true : false} isSearchable={true} isMulti={field.multiple ? true : false} isClearable={true} options={field.options} onChange={inputChangeHandler} placeholder={t("Válassz...")} {...field} /></Col>
                , field);
            case "typeahead":
                if (field.remote) {
                    return RenderTemplate(<AsyncTypeahead clearButton flip={true} minLength={1} options={field.options} onSearch={handleSearch} onChange={(e) => { inputChangeHandler(e,field); }} {...field} />, field);
                }
                else {
                    return RenderTemplate(<Typeahead clearButton flip={true} options={field.options} onChange={(e) => { inputChangeHandler(e,field); }} {...field} />, field);
                }
            case "date": 
                return RenderTemplate(<DatePicker defaultValue={field.value && field.value} onChange={inputChangeHandler} className="w-100" format={field.format ? field.format : "yyyy-MM-dd"} />, field);
            case "datetime": 
                return RenderTemplate(<DatePicker defaultValue={field.value && field.value} onChange={inputChangeHandler} className="w-100" format={field.format ? field.format : "yyyy-MM-dd HH:mm"} />, field);
            case "time": 
                return RenderTemplate(<DatePicker defaultValue={field.value && field.value} onChange={inputChangeHandler} className="w-100" format={field.format ? field.format : "HH:mm"} />, field);
            case "checkbox": 
                return RenderTemplate(<Checkbox {...field} value={field.value ? field.value : 1} checked={field.checked ? true : false} onChange={inputChangeHandler}>{field.label}</Checkbox>);
            case "radio": 
                return RenderTemplate(<Radio {...field} value={field.value ? field.value : 1} checked={field.checked ? true : false} onChange={inputChangeHandler}>{field.label}</Radio>);
            case "divider": 
                return <Divider />
            case "file":
                return RenderTemplate(<File 
                    field={field} 
                    formData={formData} 
                    id={id} 
                    setField={setField} 
                    checkSubmitDisabled={checkSubmitDisabled}
                    module={module}
                    RenderField={RenderField}
                />, field);                
            case "hidden":
                return <Form.Control type="hidden" id={field.id} name={field.name} value={field.value} />
            case "list": 
                if (field.action.search(":id") && id === 0) return RenderTemplate(<Alert variant="info">{t("A funkció használatához előbb mentsd el az űrlapot!")}</Alert>, field);
                return RenderTemplate(<Listgenerator key={field.id + "list"} {...field} action={field.action.replace(":id", id)} />, field);
            case "editor": 
                return RenderTemplate(<Editor key={field.id + "editor"} value={field.value} onChange={(e) => { inputChangeHandler(e,field); }} />, field);
            case "seo": 
                return field.children && (field.children.map((child, id) => {
                    return <RenderField key={field.id + "render-seo" + id} {...child} />;
                }))
            default:
                return RenderTemplate(<Form.Control onChange={inputChangeHandler} type={field.type} {...field} ref={ref} />, field);
        }
    });

    props.className = props.className ? props.className : "w-100";

    return (
        <Form onSubmit={onSubmit} className={props.className} ref={ref} {...props}>
            <div className="formgenerator-content" style={!Object.values(formData).some(field => field.type === "submit") ? {paddingBottom: "var(--formgenerator-footer-height)"} : {}}>
                <SkeletonTheme baseColor="#eaeaea" highlightColor="#555">
                {Object.values(formData).map((field, id) => {
                    return <RenderField key={id + "render"} {...field} ref={field.ref} />;
                })}
                </SkeletonTheme>
            </div>
            {!Object.values(formData).some(field => field.type === "submit") && (
                <div className="formgenerator-footer position-fixed border-top bg-white p-3 z-1" style={{width: "100%", height: "var(--formgenerator-footer-height)", left: 0, bottom: 0}}>
                    <div key="submit-buttons-footer" className="d-flex align-items-center justify-content-end">
                        {cancelurl && <Button variant="secondary" className={(!isLoaded && `mt-1`) + ` me-1`} onClick={(typeof cancelurl === "string" ? () => navigate(cancelurl) : cancelurl)}>{t("Mégsem")}</Button>}                        
                        {!isLoaded ? <Skeleton duration={0.8} width="100px" height="38px" /> : <Button ref={SubmitButtonRef} type="submit" variant="primary">
                            <FontAwesomeIcon icon={faSpinner} spin={true} className="d-none" ref={SubmitButtonLoaderRef} />
                            <span>{t("Mentés")}</span>
                        </Button>}
                    </div>
                </div>
            )}
        </Form>
    );
}


export default Formgenerator;