import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faBars } from '@fortawesome/free-solid-svg-icons'
import menus from '../../menu.json'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Logo from './logo';
import { IsMobile } from '../Device';
import Permission from '../Permission';

const MainSidebar = ({...props}) => {
    const [toggled, setToggled] = useState(IsMobile());    

    const RenderMenu = ({menu, ...props}) => {
        return (
            <SubMenu key={menu.name} label={menu.name} {...props}>
                {menu.children.map((item, index) => {
                    return item.children 
                    ? 
                        <RenderMenu key={item.url + item.name} menu={item} /> 
                    : 
                        <Permission permission={item.permission} /> &&
                        <MenuItem 
                            key={item.url + item.name} 
                            component={ <Link to={item.url} /> } 
                            onClick={() => setToggled(IsMobile())}
                            suffix={(item.suffix && <Permission permission={item.suffix.permission} />) && <Link to={item.suffix.url}>{item.suffix.name === "+" ? <FontAwesomeIcon icon={faPlusCircle} /> : item.suffix.name}</Link> }
                        >{item.name}</MenuItem>
                })}
            </SubMenu>
        );        
    }

    return (
        <>
            <FontAwesomeIcon className={(!toggled ? "toggled " : "") + `sidebar-hamburger position-fixed`} role="button" style={{top: "calc(var(--header-top-height) / 3 - 3px)", zIndex: 11}} size="xl" icon={faBars} onClick={() => setToggled(!toggled)}/>
            <Sidebar backgroundColor="#fff" toggled={!toggled} rootStyles={{position: "fixed", top: 0, height: "100vh", width: "var(--sidebar-left-width)", zIndex: 11}} {...props}>
                <Logo />                
                <Menu>
                    {menus.map((menu, index) => {
                        if (<Permission permission={menu.permission} />) {
                            return <RenderMenu key={index + "Menu"} menu={menu} defaultOpen />;
                        }
                    })}   
                </Menu>          
            </Sidebar>
        </>
    );
}

export default MainSidebar;