import { Link } from 'react-router-dom';
import logo from '../../assets/image/logo.jpg';

function Logo() {
    return (
        <div className="logo-container">
            <div className="logo text-center">
                <Link to="/"><img src={logo} alt="logo" style={{width: "100%", maxWidth: 150}} /></Link>
            </div>
        </div>
    );
}

export default Logo;