import { StrictMode } from 'react';
import Router from "./routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import AuthProvider from "./provider/authProvider";
import { IsMobile } from './components/Device';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/default.css';
import './assets/css/index.css';

function App() {
  const queryClient = new QueryClient();  

  return (
    <StrictMode>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Router />
        </QueryClientProvider>
      </AuthProvider>          
      <ToastContainer position={IsMobile() ? "top-left" : "bottom-left"} autoClose={5000} theme="dark" draggablePercent={60} />      
    </StrictMode>
  );
}

export default App;
