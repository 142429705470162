import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

let getCurrentLanguage = localStorage.getItem('language');
const token = JSON.parse(localStorage.getItem("remember")) ? localStorage.getItem("token") : sessionStorage.getItem("token");
i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'hu',
        lng: getCurrentLanguage,
        interpolation: {
            escapeValue: false
        },
        // parseMissingKeyHandler: (key) => {
        //     newLangVars[key] = {lang: i18n.language, name: key}
        //     return key;
        // },
        saveMissing: token ? true : false,
        backend: {
            loadPath: process.env.REACT_APP_API_END_POINT + '/../locale/{{lng}}/{{ns}}.json',
            addPath: process.env.REACT_APP_API_END_POINT + '/_locale/{{lng}}/{{ns}}',
            customHeaders: {
                Authorization: `Bearer ${token}`
            }
        }
    });

export default i18n;