import Formgenerator from "../../components/Form";
import Listgenerator from "../../components/List";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const module = "content";
const url = "/content";

const List = () => {
    const { t } = useTranslation();
    const title = t("Tartalmak");

    const fields = [
        {
            id: "name",
            label: t("Név"),
            sortable: true,
            hideable: true,
            searchable: true,
        },
        {
            id: "status",
            label: t("Státusz"),
            sortable: true,
            hideable: true,
            searchable: true,
            searchtype: "select",
            data: [
                {value: "1", label: t("Aktív")},
                {value: "0", label: t("Inaktív")}
            ]
        },
        {
            id: "_functions_"
        }
    ];

    // const handleChange = (keys) => {
    //     console.log('Selected Rows: ', keys);
    // };
    // return (<Listgenerator action={process.env.REACT_APP_API_END_POINT + `/:id`} title="Tartalmak" columns={fields} addButton selectableRows onSelectedRowsChange={handleChange} />);
    return (<Listgenerator 
        module={module}
        action={process.env.REACT_APP_API_END_POINT + `/${module}/all`} 
        deleteurl={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
        paginationServer 
        columnSearch 
        title={title} 
        columns={fields} 
        buttons={{add: {url: "", label: t("Új létrehozása")}}}
     />);
};
const Edit = () => {
    let { id } = useParams();
    
    const fields = [
        {
            "type": "card",
            "label": "Fő adatok",
            "children": [
                {
                    id: "name",
                    type: "text",
                    label: "Megnevezés",
                    required: true,
                    // multilanguage: true
                },
                {
                    id: "description",
                    type: "editor",
                    rows: 5,
                    label: "Tartalom",
                    // multilanguage: true
                },
                {
                    id: "file",
                    type: "file",
                    label: "Kép",
                    accept: "image/*",
                },
                {
                    id: "status",
                    type: "select",
                    label: "Státusz",
                    options: [
                        {value: "1", label: "Aktív"},
                        {value: "0", label: "Inaktív"}
                    ]
                }
            ]
        },
        {
            "type": "card",
            "label": "SEO",
            "children": [
                {
                    type: "seo"
                }
            ]
        }    
    ];
    return (<Formgenerator 
            id={id} 
            module={module} 
            action={process.env.REACT_APP_API_END_POINT + `/${module}/:id`} 
            cancelurl={url} 
            fields={fields} 
        />);
};

const exportedObject = { Edit, List };
export default exportedObject;